import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Breadcrumbs from "components/Breadcrumbs"
import NewsSection from "components/NewsSection"
import PostContent from "page_components/single-post/PostContent"

const SinglePost = ({ pageContext, data }) => {
  const breadcrumbs_data = [
    {
      name: "Blog",
      href: "/blog/",
    },
    {
      name: pageContext.data.title,
      href: `/blog/${pageContext.data.slug}/`,
    },
  ]

  return (
    <Layout
      seo={{
        title: pageContext?.data?.seo?.title,
        description: pageContext?.data?.seo?.metaDesc,
      }}
    >
      <Breadcrumbs breadcrumbs_data={breadcrumbs_data} />
      <PageHeader header="Blog" />
      <PostContent post={pageContext} />
      <NewsSection
        title=""
        heading="Ostatnio dodane wpisy"
        posts={data?.newPosts?.nodes}
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    newPosts: allWpPost(sort: { fields: date, order: DESC }, limit: 3) {
      nodes {
        id
        slug
        acfPosts {
          image {
            altText
            sourceUrl
          }
        }
        title
        excerpt
      }
    }
  }
`

export default SinglePost
